import axios from 'axios'

// 针对不同域名的接口，单独处理
const service = axios.create({
  timeout: 20000
})

const baseUrlOne = 'https://newbase.zhihuishu.com/'

// 查看视频地址，新的：
const getVideoByIdNew = (params) => {
  return service({
    url: `${baseUrlOne}video/initVideoNew`,
    method: 'get',
    params
  })
}

export default {
  getVideoByIdNew
}
