import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "LayOut" */ '@/views/LayOut/Home.vue'),
    meta: {
      index: 2,
      keepAlive: false,
      documentTitle: '高校课程联盟-同步课堂网'
    }
  },
  {
    path: '/playback',
    name: 'playback',
    component: () => import(/* webpackChunkName: "TestPage" */ '@/views/playback/index.vue'),
    meta: {
      index: 2,
      keepAlive: false,
      documentTitle: '同步课堂_直播回看'
    }
  },
  {
    path: '/AIExperience',
    name: 'AIExperience',
    component: () => import(/* webpackChunkName: "TestPage" */ '@/views/AIExperience/index.vue'),
    meta: {
      index: 2,
      keepAlive: false,
      documentTitle: '同步课堂_体验AI剪辑'
    }
  },
  {
    path: '/applyOpen',
    name: 'applyOpen',
    component: () => import(/* webpackChunkName: "TestPage" */ '@/views/applyOpen/index.vue'),
    meta: {
      index: 2,
      keepAlive: false,
      documentTitle: '同步课堂_申请开通'
    }
  }, {
    path: '/historyLive',
    name: 'historyLive',
    component: () => import(/* webpackChunkName: "TestPage" */ '@/views/historyLive/index.vue'),
    meta: {
      index: 2,
      keepAlive: false,
      documentTitle: '同步课堂_我的直播'
    }
  },
  {
    path: '/aiVideo',
    name: 'aiVideo',
    component: () => import(/* webpackChunkName: "aiVideo" */ '@/views/aiVideo/index.vue'),
    meta: {
      index: 2,
      keepAlive: false,
      documentTitle: '同步课堂_视频剪辑'
    }
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
