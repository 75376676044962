import request from 'utils/request'

//历史记录查询
const historyList = (params) => {
  return request({
    url: '/simple-class/web/teacher/historyLiveList',
    method: 'get',
    params
  })
}

//待开始直播列表
const toStartList = () => {
  return request({
    url: '/simple-class/web/teacher/toStartLiveList',
    method: 'get'
  })
}

//直播详情
const getDetails = (params) => {
  return request({
    url: '/simple-class/app/live/detail/v100',
    method: 'post',
    params
  })
}

//桌面端唤醒网页端登录
const goLogin = (params) => {
  return request({
    url: '/simple-class/web/loginFromDesktop',
    method: 'post',
    headers: {
      'web-ott': params
    }
  })
}

export default {
  historyList,
  toStartList,
  getDetails,
  goLogin
}
