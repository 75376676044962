const state = {
  editBtnListShow: false
}

const getters = {
  editBtnListShow (state) {
    return state.editBtnListShow
  }
}

const mutations = {
  mutationForSetEditBtnListShow (state, editBtnListShow) {
    state.editBtnListShow = editBtnListShow
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
