import http from '@/server'

const state = {
  userInfo: {},
  isLogin: false
}

const getters = {
  userInfoGetter (state) {
    const userInfo = typeof state.userInfo === 'string' ? JSON.parse(state.userInfo) : state.userInfo
    return userInfo || JSON.parse(localStorage.getItem('User_userInfo')) || {}
  }
}

const mutations = {
  Mutation_SET_USERINFO(state, info) {
    state.userInfo = JSON.stringify(info)
    localStorage.setItem('User_userInfo', state.userInfo)
  },
  Mutation_REMOVE_USERINFO(state) {
    state.userInfo = {}
    localStorage.removeItem('User_userInfo')
  },
  Mutation_SET_ISLOGIN: (state, isLogin) => {
    state.isLogin = isLogin
  },
}

const actions = {
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      http.getLoginUserInfo().then((res) => {
        const { data } = res
        if (data.code != 200) {
          // 删除状态管理和本地存储
          commit('Mutation_SET_ISLOGIN', false)
          commit('Mutation_REMOVE_USERINFO')
        } else {
          // 已登录
          // 添加状态管理并存储在本地
          commit('Mutation_SET_ISLOGIN', true)
          commit('Mutation_SET_USERINFO', data.rt)
        }
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
