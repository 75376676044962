import request from '@/utils/request'

// 本地上传需要切片的视频
const uploadVideo = (params) => {
  return request({
    url: '/simple-class/home/upload',
    method: 'post',
    params
  })
}

// 获取示例切片视频
const getSampleAIVideoResult = (params) => {
  return request({
    url: '/simple-class/open/home/sampleAIVideoResult',
    method: 'get',
    params
  })
}

// 根据用户id获取最后一次切片结果
const getAIVideoResultByUserId = (params) => {
  return request({
    url: '/simple-class/home/aiVideoResultByUserId',
    method: 'get',
    params
  })
}

// 根据直播id获取切片结果
const getAIVideoResultByLiveId = (params) => {
  return request({
    url: '/simple-class/open/home/aiVideoResultByLiveId',
    method: 'get',
    params
  })
}

// 获取直播录像讯飞转换的文字
const getAIText = (params) => {
  return request({
    url: '/simple-class/open/home/getAiText',
    method: 'post',
    params
  })
}

// 获取系列直播课
const getSeriesLiveInfo = (params) => {
  return request({
    url: '/simple-class/open/home/seriesLiveInfo',
    method: 'get',
    params
  })
}

// 获取是否展示手动剪辑按钮
const getFilmEdit = (params) => {
  return request({
    url: '/simple-class/home/checkManualCutting',
    method: 'get',
    params
  })
}

// 手动剪辑前需调用这个接口
const getInform = (params) => {
  return request({
    url: '/simple-class/home/manualCutting',
    method: 'post',
    params
  })
}

/**
 * @description: 获取视频的字幕文件
 * @param {*} params
 * @return {*}
 */
const getVideoCaptionsFile = params => {
  return request({
    url: '/simple-class/open/home/getVideoCaptionsFile',
    method: 'post',
    params
  })
}

export default {
  uploadVideo,
  getSampleAIVideoResult,
  getAIText,
  getAIVideoResultByUserId,
  getAIVideoResultByLiveId,
  getSeriesLiveInfo,
  getFilmEdit,
  getInform,
  getVideoCaptionsFile
}
