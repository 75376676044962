import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from '@/server'
import './styles/common.less'
import { Button, Input, Message, Loading, Popover, Drawer, Dialog, Scrollbar, Checkbox, Slider, Tooltip } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import infiniteScroll from 'vue-infinite-scroll'
import { isIP } from './utils/toolsForCommon'
import loading from './plugin/myLoading/loading.js'

Vue.prototype.$http = http
Vue.prototype.$message = Message

Vue.use(infiniteScroll)

Vue.use(Button)
Vue.use(Input)
Vue.use(Drawer)
Vue.use(Popover)
Vue.use(Dialog)
Vue.use(Scrollbar)
Vue.use(Loading)
Vue.use(Checkbox)
Vue.use(Slider).use(Tooltip)

Vue.config.productionTip = false

const { hostname, protocol, href } = window.location
const targetProtocol = 'https:'
// 非本地server
if (hostname !== 'localhost' && !isIP(hostname)) {
  if (protocol !== targetProtocol) {
    window.location.href = targetProtocol + href.substring(protocol.length)
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
