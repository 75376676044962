import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from './modules/userInfo'
import common from './modules/common'
import getters from './getters'
import select from '@/store/modules/select'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    userInfo,
    common,
    select
  },
  getters,
  plugins:[
    //持久化 课程选集记录
    persistedState({
      storage: window.localStorage,
      paths: ['select'],
    }),
  ]
})
