import request from '@/utils/request'

// 分页查询当天进行中直播+已完成切片任务的直播列表
const applyForOpening = (params) => {
  return request({
    url: 'simple-class/open/home/applyForOpening',
    method: 'post',
    params
  })
}

export default {
  applyForOpening
}
