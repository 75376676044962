<template>
  <div v-if="show" class="loading-container">
    <div class="loading-mask"></div>
    <div class="loading-content">
      <div class="loading-animate">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
      <div class="loading-text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.loading-container{
  position: relative;
  text-align: center;
  .loading-mask{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
  }
  .loading-content{
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1002;
    transform: translate(-50%, -50%);
    text-align: center;
    .loading-animate{
      display: inline-block;
      width: 80px;
      height: 80px;
      vertical-align: middle;
      background-size: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .spinner {
        width: 60px;
        height: 60px;
        position: relative;
        .double-bounce1, .double-bounce2 {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #FF6F33;
          opacity: 0.6;
          position: absolute;
          top: 0;
          left: 0;
          animation: bounce 2.0s infinite ease-in-out;
        }
        .double-bounce2 {
          animation-delay: -1.0s;
        }
      }
    }
    .loading-text{
      font-size: 14px;
      color: #FFF;
    }
  }
}
@keyframes bounce {
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
}
</style>
