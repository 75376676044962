/**
 * @description: 获取随机数
 */
export const getRandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * @description: 获取随机数2
 */
export const getRandomNumber = len => {
  let sum = 1
  for (let i = 0; i < len - 1; i++) {
    sum *= 10
  }
  return String(Math.floor(Math.random() * 10 * sum))
}

/**
 * @description: 匹配枚举
 */
export const getNameTextForEnumeration = (options, value) => {
  const res = options.find(item => item.value == value)
  return (res && res.name) ? res.name : '- -'
}

/**
 * @description: 处理秒
 */
export const secondsFormat = s => {
  s = Math.floor(s)
  const hour = Math.floor( s / 3600)
  const minute = Math.floor( (s - hour * 3600) /60 )
  const second = s - hour * 3600 - minute * 60
  return hour.toString().padStart(2,'0') + ":" + minute.toString().padStart(2,'0')  + ":" + second.toString().padStart(2,'0')
}

/**
 * @description: 是否火狐
 */
export const isFirefox = () => {
  return navigator.userAgent.indexOf('Firefox') >= 0
}

/**
 * @description: 是否ip格式
 */
export const isIP = ip => {
  const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/
  if (re.test(ip)) {
    if (Number(RegExp.$1) < 256 && Number(RegExp.$2) < 256 && Number(RegExp.$3) < 256 && Number(RegExp.$4) < 256) {
      return true
    } else {
      return false
    }
  }
  return false
}

/**
 * @description: 对象转form表单
 */
export function toFormData (params) {
  const formData = new FormData()
  for (const key in params) {
    formData.append(key, params[key])
  }
  return formData
}

/**
 * @method 找到树里面的某一项
 * @param
 * @return
 */
export const findItemForTree = (data, childrenName, propertyName, propertyValue) => {
  let itemRes = {}
  const find = (tree, children, key, value) => {
    tree.some(item => {
      if (item[key] == value) {
        itemRes = item
        return true
      } else if (item[children] && item[children].length > 0) {
        find(item[children], children, key, value)
      }
    })
  }
  find(data, childrenName, propertyName, propertyValue)
  return itemRes
}

// 获取类型：
export const getType = (data) => {
  return Object.prototype.toString.call(data).slice(8, -1)
}

/**
 * @method 获取浏览器类型
 * @return
 */
export const getBroswer = () => {
  const ua = navigator.userAgent
  const os = (/\s\((.*?);/.exec(ua) || [])[1]
  const browser = {}
  const firefox = ua.match(/Firefox\/([\d.]+)/)
  const ie = ua.match(/MSIE\s([\d.]+)/) || ua.match(/Trident\/.+?rv:(([\d.]+))/)
  const edge = ua.match(/Edge\/([\d.]+)/) // IE 12 and 12+
  const chrome = ua.match(/Chrome\/([\d.]+)/)
  const safari = !chrome ? ua.match(/Safari\/([\d.]+)/) : false
  const weChat = /micromessenger/i.test(ua)
  if (firefox) {
    browser.firefox = true
    browser.version = firefox[1]
  }
  if (ie) {
    browser.ie = true
    browser.version = ie[1]
  }
  if (edge) {
    browser.edge = true
    browser.version = edge[1]
  }
  if (chrome) {
    browser.chrome = true
    browser.version = chrome[1]
  }
  if (safari) {
    browser.safari = true
    browser.version = safari[1]
  }
  if (weChat) {
    browser.weChat = true
  }
  return {
    browser: browser,
    os: os
  }
}

/**
 * @method 防抖
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */

export function debounce (fn, delay) {
  let time = null;//time用来控制事件的触发
  return function () {
      if (time !== null) {
          clearTimeout(time);
      }
      time = setTimeout(() => {
          fn.call(this);
          //利用call(),让this的指针从指向window 转成指向input
      }, delay)
  }
}

/**
 * @method 数字格式化
 * @param {*} num 
 * @returns 
 */
export function formatNumber(num) {
	num = Number(num);
  if(!num) return 0

	if (num == 0) {
		return num + '';
	} else
	if (num > 1 && num < 10000) {
		return num + '';
	} else {
		return (num / 10000).toFixed(1) + 'w';
	}
}

/**
 * @method 深度克隆
 * @param {*} target 
 * @returns 
 */

export function deepClone(target) {
  // 定义一个变量
  let result
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
      // 如果是一个数组的话
      if (Array.isArray(target)) {
          result = [] // 将result赋值为一个数组，并且执行遍历
          for (const i in target) {
              // 递归克隆数组中的每一项
              result.push(deepClone(target[i]))
          }
          // 判断如果当前的值是null的话；直接赋值为null
      } else if (target === null) {
          result = null
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
      } else if (target.constructor === RegExp) {
          result = target
      } else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {}
          for (const i in target) {
              result[i] = deepClone(target[i])
          }
      }
      // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
      result = target
  }
  // 返回最终结果
  return result
}

/**
 * 
 * @param {*} substring 字符串
 * @returns 是否包含表情
 */

export function isEmojiCharacter(substring) {
  for (var i = 0; i < substring.length; i++) {
    var hs = substring.charCodeAt(i)
    if (0xd800 <= hs && hs <= 0xdbff) {
      if (substring.length > 1) {
        var ls = substring.charCodeAt(i + 1)
        var uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000
        if (0x1d000 <= uc && uc <= 0x1f77f) {
          return true
        }
      }
    } else if (substring.length > 1) {
      var ls = substring.charCodeAt(i + 1)
      if (ls == 0x20e3) {
        return true
      }
    } else {
      if (0x2100 <= hs && hs <= 0x27ff) {
        return true
      } else if (0x2b05 <= hs && hs <= 0x2b07) {
        return true
      } else if (0x2934 <= hs && hs <= 0x2935) {
        return true
      } else if (0x3297 <= hs && hs <= 0x3299) {
        return true
      } else if (
        hs == 0xa9 ||
        hs == 0xae ||
        hs == 0x303d ||
        hs == 0x3030 ||
        hs == 0x2b55 ||
        hs == 0x2b1c ||
        hs == 0x2b1b ||
        hs == 0x2b50
      ) {
        return true
      }
    }
  }
} 