import request from '@/utils/request'

// 分页查询当天进行中直播+已完成切片任务的直播列表
const courseList = (params) => {
  return request({
    url: 'simple-class/open/home/live/list',
    method: 'get',
    params
  })
}

// 快捷直播
const teacherQuickLive = () => {
  return request({
    url: 'simple-class/web/teacher/quickLive',
    method: 'post'
  })
}

// 加入直播 +1
const appJoinLive = (params) => {
  return request({
    url: 'simple-class/student/appJoinLive/v100',
    method: 'post',
    params
  })
}

export default {
  courseList,
  teacherQuickLive,
  appJoinLive
}
