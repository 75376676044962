import common from './api/common'
import home from './api/home'
import AIExperience from './api/AIExperience'
import applyOpen from './api/applyOpen'
import apiForOtherDomainName from './api/apiForOtherDomainName'
import history from './api/historyLive'
import pushLiveRoom from '@/server/api/pushLiveRoom'
import AIVideo from './api/AIVideo'

export default {
  ...common,
  ...home,
  ...AIExperience,
  ...applyOpen,
  ...apiForOtherDomainName,
  ...history,
  ...pushLiveRoom,
  ...AIVideo
}
