const state = {
  seriesLiveListNum: 1
}

const mutations = {
  edit(state, n) {
    state.seriesLiveListNum = n
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
