import request from '@/utils/request'

// 手动剪辑-切片列表
const videoClipList = (params) => {
    return request({
      url: '/simple-class/video-clip/list',
      method: 'get',
      params
    })
  }
  
// 新增切片
const addVideoClip = (params) => {
  return request({
    url: '/simple-class/video-clip/manual/add',
    method: 'post',
    data: params
  })
}
// 修改切片
const updateVideoClip = (params) => {
  return request({
    url: '/simple-class/video-clip/manual/update',
    method: 'post',
    data: params
  })
}
// 删除切片
const delVideoClip = (params) => {
  return request({
    url: '/simple-class/video-clip/manual/delete',
    method: 'post',
    params
  })
}

export default {
    videoClipList,
    addVideoClip,
    delVideoClip,
    updateVideoClip
}