import request from 'utils/request'


//获取邀请码类型
const getInviteCodeInfo = (params) => {
  return request({
    url: 'simple-class/app/live/inviteCodeInfo/v100',
    method: 'post',
    params
  })
}

const teacherJoinLive = (params) => {
  return request({
    url: 'simple-class/web/teacher/joinLive',
    method: 'post',
    params
  })
}

export default {
  getInviteCodeInfo,
  teacherJoinLive
}
