<template>
  <div id='app'>
    <!-- <transition :name="transitionName"> -->
    <router-view v-if='!$route.meta.keepAlive' :key='$route.fullPath' />
    <!-- </transition> -->
    <!-- <transition :name="transitionName"> -->
    <keep-alive>
      <router-view v-if='$route.meta.keepAlive' :key='$route.fullPath' />
    </keep-alive>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitionName: ''
    }
  },
  watch: {
    '$route': {
      handler: function(to, from) {
        if (to.meta.index && from.meta.index && (to.meta.index > from.meta.index)) {
          this.transitionName = 'slideright'
        } else if (to.meta.index && from.meta.index && (to.meta.index < from.meta.index)) {
          this.transitionName = 'slideleft'
        } else {
          this.transitionName = 'slideright'
        }
        document.title = to.meta.documentTitle ? to.meta.documentTitle : '同步课堂_智慧树'
      },
      immediate: true
    }
  }
}
</script>

<style lang='less' scoped>
@import './styles/theme.css';

#app {
  min-width: 1080px;
  height: 100%;
}
</style>
