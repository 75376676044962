import request from '@/utils/request'

// 例：
/**
 * @description: 获取登录用户管理的学校
 * @param {*} params
 * @return {*}
 */
const getAdminUserSchool = () => {
  return request({
    url: '/admin/school/adminUserSchool/v100',
    method: 'get'
  })
}

// 获取用户登录信息
const getLoginUserInfo = () => {
  return request({
    url: 'simple-class/zhs/getLoginUserInfo',
    method: 'get'
  })
}

// 生成二维码
const generateScreenQrCode = (params) => {
  return request({
    url: 'simple-class/qrcode/generateScreenQrCode/v100',
    method: 'get',
    params
  })
}

// 获取扫码信息：
const getInfoByQrCode = (params) => {
  return request({
    url: 'simple-class/qrcode/getByQrCode/v100',
    method: 'get',
    params
  })
}

/**
 * @description: 获取课程录像信息
 * @param {*} params
 * @return {*}
 */
const getVideoRecordInfo = params => {
  return request({
    url: 'simple-class/app/live/getVideoRecordInfo',
    method: 'get',
    params
  })
}

/**
 * @description: 编辑文字
 * @param {*} params
 * @return {*}
 */
const editWordContent = params => {
  return request({
    url: 'simple-class/home/editWordContent',
    method: 'post',
    data: params
  })
}

/**
 * @description: 获取直播下的资源文件列表
 * @param {*} params
 * @return {*}
 */
const homeResourceList = params => {
  return request({
    url: 'simple-class/home/resourceList',
    method: 'post',
    params
  })
}

/**
 * @description: 获取主讲老师下的翻转课程列表
 * @param {*} params
 * @return {*}
 */
const homeFlippedCourseList = () => {
  return request({
    url: 'simple-class/home/flippedCourseList',
    method: 'post'
  })
}

/**
 * @description: 同步资源文件至翻转课资源库
 * @param {*} params
 * @return {*}
 */
const homeSyncResource = params => {
  return request({
    url: 'simple-class/home/syncResource',
    method: 'post',
    data: params
  })
}

export default {
  getAdminUserSchool,
  getLoginUserInfo,
  generateScreenQrCode,
  getInfoByQrCode,
  getVideoRecordInfo,
  editWordContent,
  homeResourceList,
  homeFlippedCourseList,
  homeSyncResource
}
