import axios from 'axios'

const service = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000
})

// request interceptors:
service.interceptors.request.use(
  config => {
    // return config
    config.headers.env = process.env.NODE_ENV
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptors:
service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
